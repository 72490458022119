import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle'
import './assets/css/novocel.css'
import { createApp } from 'vue'

/*---------  PARA USAR EN HOME -----------*/
/*-- COMENTAR SI SE QUIERE USAR LANDING --*/
import router from "@/router/index.js";  
import App from './App.vue'; 

/*----------  PARA USAR EN LANDING ------------*/
/*------ COMENTAR SI SE QUIERE USAR HOME ------*/
//  import router from "@/router/indexLanding.js"; 
// import App from './LandingApp.vue';  

/*----------  PARA USAR EN LANDING HUMBERTO ------------*/
/*--------- COMENTAR SI SE QUIERE USAR HUMBERTO --------*/
/* import router from "@/router/indexHbto.js"; 
import App from './LandingHbto.vue';   */

const app = createApp(App);
app.use(router);
app.mount('#app');
export { app };