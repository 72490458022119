<template>
    <div class="card">
        <div class="image">
            <router-link :to=link :aria-label=title>
                <img
                    :srcset="`${srcWebp320} 480w, ${srcWebp480} 768w, ${srcWebp480} 1080w, ${srcWebp480} 1200w, ${srcWebp480} 1400w`"
                    sizes="(max-width: 400px) 100%, (max-width: 480px) 100%, (max-width: 768px) 100%, (max-width: 1080px) 100%, 1200px"
                    :src=srcWebp480 :alt=altImg loading="lazy" :class=classes 
                />
            </router-link>
        </div>
        <div class="decoration"></div>
        <div class="text">
            <div>
                <p class="section">{{ section }}</p>
                <p class="title">{{ title }}</p>
            </div>
            <div>
                <p class="description"> {{ description }}</p>
                <ButtonAction text="Seguir leyendo" backColor="none" :link=link />
            </div>
        </div>
    </div>
</template>

<script>
import ButtonAction from './ButtonAction.vue';

export default {
    name: 'CardNew',
    components: {
        ButtonAction
    },
    props:  {
        section: {
            type: String,
        },
        title: {
            type: String,
        },
        description: {
            type: String,
        },
        link: {
            type: String,
        },
        altImg: { type: String, },
        classes: { type: String, },
        srcWebp: { type: String, },
        srcWebp320: { type: String, },
        srcWebp480: { type: String, },
        srcWebp768: { type: String, },
        srcWebp1080: { type: String, },   
    },
}
</script>

<style scoped>
.card{
    background-color: unset;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}
.image{
    max-width: 90%;
    height: 250px;
    overflow: hidden;
    border-radius: 1rem;
    position: relative;
    z-index: 2;
    transition: all 0.3s ease-in-out;
}
.image:hover{
    transform: scale(1.02);
}
.image img{
    object-fit: cover;
    height: 250px;
    width: 100%;
}
.decoration{
    height: 160px;
    width: 100%;
    border-radius: 1rem;
    position: relative;
    bottom: 135px;
    margin-bottom: -135px;
}
.news-card:nth-child(2n) .decoration {
background-color: var(--soft-violet);
}
.news-card:nth-child(2n+1) .decoration {
    background-color: var(--light-violet);
}
.text{
    margin: 1rem 5%;
    min-height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.section{
    text-transform: uppercase;
    font-size: 0.9rem;
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: 0.5rem;
}
.title{
    font-weight: 600;
    font-size: 35px;
    line-height: 38px;
    margin-bottom: 0.5rem;
}
.description{
    font-weight: 500;
    font-size: 0.95rem;
    line-height: 1.3rem;
    margin-bottom: 1rem;
}
</style>