import { createRouter, createWebHistory } from "vue-router";
import Home from '@/views/HomePage/HomePage.vue';
/* const Home = () => import('@/views/HomePage/HomePage.vue'); */
const TraumatologyPage = () => import('@/views/TraumatologyPage/TraumatologyPage.vue');
const DolorPage = () => import('@/views/DolorPage/DolorPage.vue');
const TreatmentsPage = () => import('@/views/TreatmentsPage/TreatmentsPage.vue');
const PathologyPage = () => import('@/views/PathologyPage/PathologyPage.vue');
const FAQsPage = () => import('@/views/FAQsPage/FAQsPage.vue');
const BlogPage = () => import('@/views/BlogPage/BlogPage.vue');
const AboutUsPage = () => import('@/views/AboutUsPage/AboutUsPage.vue');
const ContactPage = () => import('@/views/ContactPage/ContactPage.vue');
const BlogPost = () => import('@/views/BlogPage/BlogPost.vue');
const PricesPage = () => import('@/views/PricesPage/PricesPage.vue');
const LandingPage = () => import('@/views/LandingPage/LandingPage.vue');

const routes = [
    { path: '/', component: Home },
    { path: '/traumatologia', component: TraumatologyPage },
    { path: '/dolorcronico', component: DolorPage },
    { path: '/tratamientos', component: TreatmentsPage },
    { path: '/patologias', component: PathologyPage },
    { path: '/preguntas', component: FAQsPage },
    { path: '/blog', component: BlogPage },
    { path: '/blog/post/:id', component: BlogPost },
    { path: '/nosotros', component: AboutUsPage },
    { path: '/contacto', component: ContactPage },
    { path: '/valores', component: PricesPage },
    { path: '/landing', component: LandingPage },
];

const router = createRouter({
    /* history: createWebHistory(), */
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;