/* IMAGES */
/* import CellPng from '../../assets/img/photos/png/cell.png';
import CellJpg from '../../assets/img/photos/jpg/cell.jpg';
import CellWebp from '../../assets/img/photos/webp/cell.webp'; */

import Cell2Webp from '../../assets/img/blog/webp/cell2.webp';
import Cell2Webp320 from '../../assets/img/blog/webp/cell2_320.webp';
import Cell2Webp480 from '../../assets/img/blog/webp/cell2_480.webp';
import Cell2Webp768 from '../../assets/img/blog/webp/cell2_768.webp';
import Cell2Webp1080 from '../../assets/img/blog/webp/cell2_1080.webp';

import CellsWebp from '../../assets/img/blog/webp/cells.webp';
import CellsWebp320 from '../../assets/img/blog/webp/cells_320.webp';
import CellsWebp480 from '../../assets/img/blog/webp/cells_480.webp';
import CellsWebp768 from '../../assets/img/blog/webp/cells_768.webp';
import CellsWebp1080 from '../../assets/img/blog/webp/cells_1080.webp';

/*import KneeWebp from '../../assets/img/blog/webp/knee.webp';
import KneeWebp320 from '../../assets/img/blog/webp/knee_320.webp';
import KneeWebp480 from '../../assets/img/blog/webp/knee_480.webp';
import KneeWebp768 from '../../assets/img/blog/webp/knee_768.webp';
import KneeWebp1080 from '../../assets/img/blog/webp/knee_1080.webp';
*/
import CellWebp from '../../assets/img/blog/webp/cell.webp';
import CellWebp320 from '../../assets/img/blog/webp/cell_320.webp';
import CellWebp480 from '../../assets/img/blog/webp/cell_480.webp';
import CellWebp768 from '../../assets/img/blog/webp/cell_768.webp';
import CellWebp1080 from '../../assets/img/blog/webp/cell_1080.webp';

/* BLOG INFO */

let blog = [
    {
        id: 'celulas_madre',
        classSection: "stem-cells",
        section: "Células madres", 
        title: "¿Qué son las células madre y para qué sirven?",
        subtitle: "Las células madre mesenquimales son un tipo de células madre adultas que se encuentran en varios tejidos del cuerpo, como la médula ósea, el tejido adiposo y el tejido conjuntivo. Estas células tienen la capacidad de diferenciarse en varios tipos de células del cuerpo, como osteoblastos, adipocitos y miocitos.",
        description: "Las células madre son células que tienen la capacidad de dividirse y dar lugar a diferentes tipos de células especializadas.",
        srcWebp: Cell2Webp,
        srcWebp320: Cell2Webp320,
        srcWebp480: Cell2Webp480,
        srcWebp768: Cell2Webp768,
        srcWebp1080: Cell2Webp1080,
        altImg: "Células madres", 
        date: '13 de enero de 2023',
        author: 'Cinthia Briceño Rosas',
        fullText: `
            Las células madre mesenquimales son células que se encuentran en nuestro cuerpo durante toda nuestra vida y que se caracterizan por dos propiedades fundamentales: su capacidad de auto renovación, es decir, de dividirse para formar una copia de sí misma, y su potencial de diferenciación, es decir, de poder convertirse en distintos tipos de células de nuestro organismo. \n
            Su función es conservar el equilibrio fisiológico, manteniendo la estructura y función de los tejidos. Las utilizamos a diario para reemplazar células o tejidos que se han dañado o desgastado, actuando como un sistema reparador. Sin embargo, conforme envejecemos, va disminuyendo su calidad y cantidad, motivo por el cual, resulta beneficioso administrar células de alto potencial. \n
            El tratamiento combinado para lesiones de cartílago implementado por Clínica Novocel incluye 2 elementos fundamentales: células madre mesenquimales derivadas de tejido de cordón umbilical (células con el más alto potencial clínico, con propiedades anti-inflamatorias, inmunomoduladoras y regenerativas) y ácido hialurónico compatible biológicamente, producto que no sólo actúa como amortiguador y lubricante de la articulación, sino que también favorece la sobrevida y actividad de las células madre. \n
            El objetivo del tratamiento combinado de células madre y ácido hialurónico es que el paciente disminuya los signos artrósicos, aumente su funcionalidad articular y recupere movilidad. Se estimula la capacidad regenerativa del organismo para revertir los mecanismos patológicos que conllevan al desgaste de cartílago, logrando disminuir el dolor y la inflamación, al mismo tiempo que se propicia la regeneración de cartílago articular, manteniendo los beneficios clínicos por un amplio periodo de tiempo.
        `
    },
    {
        id: 'infiltracion',
        classSection: "stem-cells",
        section: "Células madres", 
        title: "¿Qué pasa después de la infiltración?",
        subtitle: "Muchos pacientes tienen dudas acerca de qué pasa despúes del procedimiento. Esto es lo que puedes esperar después de un procedimiento de infiltración intrarticular con células madre.",
        description: "Después de la infiltración con células madre viene la recuperación y rehabilitación. Conoce aquí lo que ocurre luego del procedimiento.",
        srcWebp: CellWebp,
        srcWebp320: CellWebp320,
        srcWebp480: CellWebp480,
        srcWebp768: CellWebp768,
        srcWebp1080: CellWebp1080,
        altImg: "Rodilla en recuperación", 
        date: '27 de agosto de 2023',
        author: 'Cinthia Briceño Rosas',
        fullText: `
            Hasta la fecha, en Clínica Novocel, hemos tratado a más de 150 pacientes con la terapia traumatológica de infiltración intrarticular de células madre y ácido hialurónico, como terapia combinada de medicina regenerativa. A todos nuestros pacientes les entregamos, desde nuestra consulta científica, los conceptos biológicos del procedimiento, les explicamos los principios y qué esperar después del procedimiento. \n
            Sin embargo, la pregunta sigue presente... ¿Qué esperar despúes del procedimiento con células madre? \n
            Despúes de la infiltración que realiza nuestro traumatólogo en nuestra sala de procedimiento autorizada, llega el momento de volver a casa. Sabrás con anticipación que debes evitar hacer fuerza y movimientos bruscos, por lo que seguramente estarás acompañado por alguien cercano a quien posiblemente le debas un café por haberte esperado :) No te preocupes... nosotros ya le habremos ofrecido un café, así que no debiera cobrarte nada. \n
            Te irás a casa y te entregaremos una compresa fría para que apliques en la zona de la infiltración. Probablemente sientas un poco de dolor debido a que hemos agregado en tu articulación un volumen que antes no existía, así que sentirás cierta inflamación en la zona intervenida. Para tratar esta inflamación, el traumatólogo te entregará una orden médica para que puedas adquirir analgésicos que te ayudarán en caso de dolor. Es super importante suspender los medicamentos que te indiquen suspender, y respetar las dosis indicadas. \n
            Luego, vienen 2 días de recuperación. Durante estos 2 días debes evitar hacer movimientos bruscos, y sobre todo seguir la recomendación médica que recibirás, como no consumir ciertos medicamentos y empezar tu terapia kinesiológica. Puedes sentir dolor, o puede ser que no sientas dolor. En cualquier caso, siempre tendrás los medicamentos de apoyo y el teléfono directo de nuestra directora científica quien estará disponible por cualquier duda. Recuerda no hacer movimientos bruscos por 2 días, esto incluye: evitar subir y bajar escaleras, no trotar ni correr, y no bailar. Aunque si suena un rock & roll deberás apagar la música lo más pronto posible para evitar que tu cuerpo se apodere de ti y se ponga a bailar solo =) \n
            Ya entrarás a la etapa de recuperación después de los primeros días. La zona intervenida comenzará a desinflamarse y podrás retomar tus actividades. Es muy importante iniciar tus terapias kinesiológicas lo más pronto posible para rehabilitarte debidamente. Recuerda que en Novocel puedes tomar tus sesiones de kinesiología. \n
            Recibirás un llamado de nuestra directora científica para saber cómo ha evolucionado todo dentro de las primeras semanas. \n
            Transcurrido 1 mes después de la intervención, será el momento de tu control. Si todo va bien, nuestro equipo médico te dará el alta médica. Podrás ayudarnos con un testimonio el día de tu control así podamos ayudar juntos para que otros pacientes conozcan estas terapias. Seguiremos en contacto contigo en los meses sucesivos para registrar que todo vaya bien. \n
            ¿Qué pasa entonces después del procedimiento con células madre? Súper fácil: Aplicar compresas de frío, consumir los medicamentos indicados solo en caso de dolor o inflamación, evitar ciertos medicamentos que te hayamos indicado según tu caso, realizar reposo los 2 primeros días, iniciar tu proceso de rehabilitación, y asistir a tu control para evaluar tu evolución. \n
            ¡Nos vemos en Clínica Novocel!
        `
    },
    {
        id: 'historia',
        classSection: "stem-cells",
        section: "Células madres", 
        title: "La historia de las Células Madre",
        subtitle: "Los hitos científicos más relevantes desde 1970 asociados a las células madre",
        description: "El estudio de las células madre ha sido un viaje de descubrimientos que ha cambiado la medicina moderna. Desde la identificación de las células madre mesenquimales (MSCs) en los años 70, estas células han mostrado un enorme potencial en la regeneración de tejidos y el tratamiento de enfermedades como la artrosis.",
        srcWebp: CellsWebp,
        srcWebp320: CellsWebp320,
        srcWebp480: CellsWebp480,
        srcWebp768: CellsWebp768,
        srcWebp1080: CellsWebp1080,
        altImg: "Células madres", 
        date: '11 de noviembre de 2024',
        author: 'Cinthia Briceño Rosas',
        fullText: `La historia de las células madre y su aplicación en medicina regenerativa es un recorrido fascinante que ha revolucionado el tratamiento de diversas enfermedades. Desde la década de 1970, los avances en el estudio de las células madre mesenquimales (MSCs) han dado lugar a tratamientos innovadores que buscan restaurar tejidos y mejorar la calidad de vida. \n

                Acompáñanos en este viaje fascinante a través de los hitos y descubrimientos que han llevado a la medicina a un nivel de innovación sin precedentes, y descubre cómo el futuro de la salud ya está aquí.

                Principales Hitos de las Células Madre Mesenquimales (MSCs)\n
                
                1976 - Descubrimiento de las MSCs: 
                En 1976, el científico soviético Alexander Friedenstein realizó un descubrimiento revolucionario al identificar en la médula ósea un tipo especial de células con capacidades regenerativas únicas, conocidas hoy como células madre mesenquimales (MSCs). Friedenstein observó que estas células, además de tener la capacidad de dividirse y formar colonias (es decir, eran clonogénicas), podían diferenciarse en diversos tipos de tejidos como hueso, cartílago, grasa y músculo.
                Este hallazgo fue un gran avance, ya que en ese momento, la investigación en células madre estaba en sus etapas iniciales, y las posibilidades de utilizar células para reparar y regenerar tejidos dañados apenas comenzaban a vislumbrarse. Las MSCs demostraron que la médula ósea no solo contenía células hematopoyéticas (que generan células sanguíneas), sino también células madre con un potencial regenerativo más amplio.
                Este descubrimiento sentó las bases para el desarrollo de la medicina regenerativa y abrió las puertas a estudios posteriores que exploraron cómo las MSCs podían aplicarse en la reparación de tejidos dañados. Desde entonces, el trabajo de Friedenstein ha inspirado décadas de investigación y ha permitido la evolución de terapias basadas en MSCs para tratar enfermedades degenerativas, como la artrosis, demostrando el poder y la versatilidad de estas células en la medicina moderna.
                Referencia: Friedenstein AJ, Chailakhjan RK, Lalykina KS. "The development of fibroblast colonies in monolayer cultures of guinea-pig bone marrow and spleen cells." Cell and Tissue Kinetics, 1970. \n
                
                1991 - Término "Célula Madre Mesenquimal": En 1991, el investigador estadounidense Arnold Caplan hizo una contribución clave al acuñar el término “célula madre mesenquimal” (MSC), que transformó la forma en que la comunidad científica entendía y clasificaba estas células. Caplan observó que las células derivadas de la médula ósea poseían un potencial extraordinario para diferenciarse en varios tipos de tejidos, incluyendo hueso, cartílago, grasa y músculo. Esta capacidad de transformarse en diferentes linajes celulares las definía como células multipotentes.
                Al proponer el término “célula madre mesenquimal,” Caplan no solo describía su capacidad de diferenciación, sino también su papel potencial en medicina regenerativa. Con su visión innovadora, sugirió que estas células podían utilizarse para regenerar tejidos y reparar daños en el cuerpo, una idea pionera para la época que amplió considerablemente las aplicaciones clínicas de las MSCs.
                El trabajo de Caplan fue fundamental para catalizar la investigación y el desarrollo de terapias regenerativas, ya que proporcionó una definición formal y un marco conceptual para estudiar estas células. Desde entonces, las MSCs han sido el foco de miles de estudios y se han convertido en una herramienta prometedora para tratar una variedad de enfermedades degenerativas, como la artrosis y otras condiciones ortopédicas.
                Referencia: Caplan AI. "Mesenchymal stem cells." Journal of Orthopaedic Research, 1991.\n
                
                2000 - Estudios en Artrosis Animal: En el año 2000, los estudios en animales marcaron un avance decisivo en la investigación sobre células madre mesenquimales (MSCs) al demostrar que estas células podían reparar el cartílago dañado en modelos animales con artrosis. Investigadores como Murphy y su equipo realizaron experimentos en los que inyectaron MSCs en articulaciones afectadas, observando una mejora significativa en la estructura del cartílago y una reducción en los síntomas asociados a la artrosis.
                Estos experimentos mostraron que las MSCs no solo tenían el potencial de diferenciarse en células de cartílago, sino también de modular la inflamación en las articulaciones, un factor clave en la progresión de la artrosis. Al reducir la inflamación y favorecer la regeneración del cartílago, estas células demostraron ser una alternativa innovadora a los tratamientos convencionales, que solo alivian los síntomas sin detener el daño.
                Estos primeros estudios en animales abrieron el camino para explorar el uso de MSCs en el tratamiento de la artrosis en humanos. Desde entonces, la terapia con MSCs ha ganado interés como una estrategia para restaurar la función articular en personas con artrosis, ofreciendo una solución que no solo trata los síntomas, sino que también aborda las causas subyacentes del desgaste en el cartílago. Este enfoque innovador ha inspirado décadas de investigación en medicina regenerativa y ha sentado las bases para los ensayos clínicos en humanos que vendrían años después
                Referencia: Murphy JM et al. "Stem cell therapy in a caprine model of osteoarthritis." Arthritis & Rheumatism, 2003.\n
                
                2006 - Definición de MSCs por la ISCT: La Sociedad Internacional para la Terapia Celular establece criterios mínimos para definir las MSCs, como marcadores específicos y capacidad de diferenciación.
                Referencia: Dominici M et al. "Minimal criteria for defining multipotent mesenchymal stromal cells. The International Society for Cellular Therapy position statement." Cytotherapy, 2006.\n
                
                2010 - Ensayos Clínicos en Humanos: En 2010, comenzaron los primeros ensayos clínicos en humanos utilizando células madre mesenquimales (MSCs) para tratar la artrosis de rodilla, marcando un hito en la medicina regenerativa. Estos estudios pioneros evaluaron la seguridad y eficacia de las MSCs autólogas (células madre extraídas del propio paciente) al ser implantadas en las articulaciones dañadas por la artrosis.
                Los resultados fueron prometedores: los pacientes experimentaron una mejora en la movilidad articular y una reducción del dolor en comparación con los tratamientos convencionales. Además, las imágenes mostraron indicios de regeneración del cartílago y reducción de la inflamación, sugiriendo que las MSCs no solo ayudaban a aliviar los síntomas, sino que también podían reparar y restaurar el tejido dañado.                Referencia: Centeno CJ et al. "Increased knee cartilage volume in degenerative joint disease using percutaneously implanted, autologous mesenchymal stem cells." Pain Physician, 2008.\n
                
                2016 - Aprobación en Japón: En 2016, Japón se convirtió en uno de los primeros países en aprobar oficialmente terapias basadas en células madre mesenquimales (MSCs) para el tratamiento de enfermedades articulares, incluyendo la artrosis. Esta aprobación fue un paso importante no solo para Japón, sino para el campo de la medicina regenerativa a nivel mundial, ya que estableció un marco regulatorio específico que permitía el uso de estas terapias en entornos clínicos de manera controlada y segura.
                El gobierno japonés, conocido por su enfoque progresivo en el campo de la biotecnología y la medicina regenerativa, impulsó regulaciones que permitieron a las clínicas aplicar terapias con MSCs, siempre que estas fueran seguras y ofrecieran beneficios potenciales para los pacientes. Con esta aprobación, Japón dio prioridad a los pacientes con enfermedades articulares, ofreciendo una opción innovadora para quienes buscaban tratamientos más efectivos y menos invasivos que la cirugía o los medicamentos convencionales.
                Este hito fue pionero en abrir camino para la aceptación de las MSCs en el ámbito clínico y alentó a otros países a considerar la regulación de terapias regenerativas. Desde entonces, Japón ha continuado liderando la adopción de terapias basadas en células madre, posicionándose como un referente en el uso de tratamientos avanzados que ofrecen una nueva esperanza para los pacientes con artrosis y otras condiciones degenerativas.
                Referencia: Sipp D. "Conditional approval: Japan lowers the bar for regenerative medicine products." Cell Stem Cell, 2015.\n
                
                2018-2020 - Avances en Terapias Personalizadas: En 2020, la medicina regenerativa avanzó significativamente con el desarrollo de nuevas técnicas de criopreservación y expansión de células madre mesenquimales (MSCs). Estas innovaciones permitieron mejorar tanto la eficacia como la accesibilidad de los tratamientos basados en MSCs, abriendo las puertas a terapias más personalizadas y adaptadas a las necesidades de cada paciente.

                - Criopreservación: Esta técnica permite almacenar las MSCs a muy bajas temperaturas durante largos períodos sin que pierdan su capacidad regenerativa. Gracias a la criopreservación, los pacientes pueden disponer de sus propias células en el momento que lo necesiten, sin necesidad de repetir el proceso de extracción. Esto facilita tratamientos continuos y reduce los tiempos de espera, permitiendo que las terapias con células madre se integren mejor en los planes de cuidado a largo plazo.
                - Expansión celular: La expansión permite multiplicar las MSCs en el laboratorio, obteniendo una cantidad mayor de células a partir de una muestra inicial. Esto es esencial en terapias personalizadas, ya que asegura que el paciente reciba la cantidad de MSCs necesaria para lograr una regeneración eficaz en el tejido afectado. Además, este proceso permite ajustar las dosis y personalizar los tratamientos según la gravedad de la enfermedad o la condición específica del paciente..
                Referencia: Galipeau J, Sensébé L. "Mesenchymal Stromal Cells: Clinical Challenges and Therapeutic Opportunities." Cell Stem Cell, 2018.\n
                
                
                La historia de las Células Madre tiene Premios Nobel también. ¡Nada menor! 
                Si bien los premios Nobel en esta área no se centran directamente en las MSCs, han sido fundamentales para el desarrollo del campo de células madre:\n

                1990 - Joseph Murray y Donnall Thomas: Reciben el Nobel por avances en trasplantes celulares, estableciendo una base para terapias con células madre.
                Referencia: Nobel Prize in Physiology or Medicine 1990.\n
                
                2007 - Mario Capecchi, Martin Evans y Oliver Smithies: Reciben el Nobel por sus trabajos en células madre embrionarias de ratón, impulsando la investigación genética en células madre.
                Referencia: Nobel Prize in Physiology or Medicine 2007.\n
                
                2012 - John Gurdon y Shinya Yamanaka: Descubren cómo reprogramar células adultas en células pluripotentes inducidas (iPSCs), revolucionando la medicina regenerativa.
                Referencia: Nobel Prize in Physiology or Medicine 2012.\n

                En Clínica Novocel, aplicamos los avances más recientes en terapias con MSCs para condiciones como la artrosis, ofreciendo tratamientos innovadores y personalizados que buscan restaurar la salud y mejorar la calidad de vida de nuestros pacientes. Contáctanos para conocer más sobre cómo nuestras terapias pueden ayudarte a vivir con menos dolor y mayor movilidad.
                Ya sabes, si alguna vez te preguntan de dónde provienen las células madre que te infiltraste en Clínica Novocel, puedes partir diciendo: "En 1970 ..." `,
    }, 
        /* {
        id: 5,
        classSection: "regenerative-medicine",
        section: "Medicina regenerativa", 
        title: "¿Qué es la medicina regenerativa?",
        subtitle: "Ejemplo de subtitulo",
        description: "La medicina regenerativa es un campo de la medicina que se centra en utilizar terapias para regenerar o reparar tejidos o órganos dañados o enfermos.",
        srcWebp: Cell2Webp,
        srcPng: Cell2Png,
        srcJpg: Cell2Jpg, 
        altImg: "Medicina regenerativa", 
        date: '',
        author: '',
        fullText: ``
    }, */
]

export default blog;