<template>      
    <section id="team" class="carousel-cards-container">
        <TitleSecodary ppalText="Equipo de Clínica Novocel" class="title" />
        <swiper 
        :breakpoints="{
            768: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 3,
            },
        }" 
        :spaceBetween="30" 
        :modules="modules" 
        :autoplay="{
            delay: 0,
            disableOnInteraction: true,
        }" 
        :loop="true" 
        :speed="4000" 
        :centeredSlides="true" 
        class="mySwiper m-0">
            <swiper-slide>
                <ImageTextCard 
                to="/nosotros"
                class="image-text-card"
                :imgLink='Humberto' 
                name="Dr. Humberto Verdugo Marchese"
                profession="Traumatólgo y Director Médico"
                description="Médico cirujano de la Universidad de Valparaíso, especialista en Traumatología y Ortopedia."
                />
            </swiper-slide>
            <swiper-slide>
                <ImageTextCard 
                to="/nosotros"
                class="image-text-card"
                :imgLink="Flores" 
                name="Héctor Flores"
                profession="Traumatólogo"
                description="Médico cirujano especialista en traumatología. Experto en extremidades inferiores y oncología ortopédica."
                />
            </swiper-slide>
            <swiper-slide>
                <ImageTextCard 
                to="/nosotros"
                class="image-text-card"
                :imgLink="Tapia" 
                name="Dr. Fernando Tapia"
                profession="Médico especialista en dolor crónico"
                description="Médico cirujano de la Universidad de Valparaíso. 
                Especialista en medicina del dolor."
                />
            </swiper-slide>      
            <swiper-slide>
                <ImageTextCard 
                to="/nosotros"
                class="image-text-card"
                :imgLink="Rolich" 
                name="Dr. Rolich Escobar"
                profession="Cirujano Plástico, Estético y Reconstructivo"
                description="Médico cirujano de la Universidad de Valparaíso. 
                Director del área de medicina estética de Clínica Novocel."
                />
            </swiper-slide>      
            <swiper-slide>
                <ImageTextCard 
                to="/nosotros"
                class="image-text-card"
                :imgLink='Cinthia' 
                name="Ing. Cinthia Briceño Rosas"
                profession="Directora Científica"
                description="Ingeniera en biotecnología de la Universidad Andrés Bello. Especialista en medicina regenerativa y proyectos de investigación."
                />
            </swiper-slide>
            <swiper-slide>
                <ImageTextCard 
                to="/nosotros"
                class="image-text-card"
                :imgLink='Claudio' 
                name="Ing. Claudio Carreño B."
                profession="Director Comercial"
                description="Ingeniero Civil Electrónico de la PUCV. Especialista en innovación y transformación digital. 
                            Socio y director comercial de Kausana y Clínica Novocel."
                />
            </swiper-slide>
            <swiper-slide>
                <ImageTextCard 
                to="/nosotros"
                class="image-text-card"
                :imgLink='Patricia' 
                name="Dra. Patricia Jeria."
                profession="Médico Geriatra"
                description="Médico Geriatra y Gerontóloga especializada en España."
                />
            </swiper-slide>
         
        </swiper>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode, Autoplay } from 'swiper/modules';
import ImageTextCard from '@/components/ImageTextCard.vue';
import TitleSecodary from '@/components/TitleSecodary.vue';
import Cinthia from '../../assets/img/team/jpg/Cinthia.jpg';
import Claudio from '../../assets/img/team/jpg/Claudio.jpg';
import Patricia from '../../assets/img/team/jpg/Patricia.jpg';
import Humberto from '../../assets/img/team/jpg/Humberto.jpg';
import Tapia from '../../assets/img/team/jpg/TapiaFernando.jpg';
import Flores from '../../assets/img/team/jpg/Flores.jpg';
import Rolich from '../../assets/img/team/png/rolich-web-hq-900.png';

export default {
    name: "SectionTeam",
    components: {
        TitleSecodary,
        Swiper,
        SwiperSlide,
        ImageTextCard,
    },
    setup() {
        return {
            modules: [FreeMode, Autoplay],
        };
    },
    data() {
        return {
            Cinthia: Cinthia,
            Claudio : Claudio, 
            Humberto : Humberto,
            Tapia : Tapia,
            Flores : Flores,
            Patricia : Patricia,
            Rolich : Rolich,
        }
    },
};
</script>


<style scoped>
#team{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: 4rem;
    max-width: 1600px;
    margin: auto;
}
.title{
    margin-top: 4rem;
    margin-bottom: 2rem;
    text-align: center;
    max-width: 600px;
    align-self: center;
}
.mySwiper {
    cursor: grab;
    overflow: visible;
}
.carousel-cards-container {
    margin-top: 3rem;
}
#team .swiper-slide:not(.swiper-slide-active),
#team .swiper-slide {
    transition: all 2s ease-in-out;
}
#team .swiper-slide:not(.swiper-slide-active ){
    transform: scale(0.9);
}
#team .swiper-slide-active {
    transform: scale(1.1) translateY(38px);
}
</style>
