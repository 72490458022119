<template>
    <section class="blog mt-8">
        <SectionName text="Blog" class="d-flex justify-content-center" />
        <TitleSecodary class="text-center mt-4" ppalText="Últimas noticias"/>
        <div class="news-card-container gap-3 mt-5">
            <template v-for="item in reorderedItems.slice(0, 3)" :key="item.id">
                <CardNew
                class="news-card"
                :class="item.classSection"
                :srcWebp="item.srcWebp"
                :srcWebp320="item.srcWebp320"
                :srcWebp480="item.srcWebp480"
                :srcWebp768="item.srcWebp768"
                :srcWebp1080="item.srcWebp1080"  
                :altImg="item.altImg"
                :section="item.section"
                :title="item.title"
                :description="item.description"
                :link="'/blog/post/' + item.id"
                />
            </template>
        </div>
    </section>
</template>

<script>
import SectionName from '@/components/SectionName.vue';
import TitleSecodary from '@/components/TitleSecodary.vue';
import CardNew from '@/components/CardNew.vue';
import BlogNews from '../BlogPage/blogNews.js';

export default {
    name: 'SectionBlog',
    components: {
        SectionName,
        TitleSecodary,
        CardNew,
    },
    data() {
        return {
            BlogNews : BlogNews,
        }
    },
    computed: {
        reorderedItems() {
            return this.BlogNews.slice().reverse();
        },
    },
}
</script>

<style scoped>
.news-card-container{
    display: grid;
    grid-template-columns: 1fr;
    max-width: 1400px;
    margin: auto;
}

/* RESPONSIVE */
@media (min-width:768px) {
    .news-card-container{
        grid-template-columns: 1fr 1fr;
    }
}
@media (min-width:992px) {
    .news-card-container{
        grid-template-columns: 1fr 1fr 1fr;
    }
}
</style>