<template>
  <main>
    <SectionAnimatedPptPhotoMobile v-if="isSmallScreen" />
    <SectionAnimatedPresentation v-else />
    <div :class="{ 'second-content': !isSmallScreen}">
      <SectionClinic />
      <SectionTreatments />
      <SectionAppointment />
      <SectionPathologies />
      <SectionTeam />
      <SectionContact />
      <SectionBlog />
    </div>
  </main>
  <FooterBar class="mt-6" />
</template>

<script>
import SectionAnimatedPptPhotoMobile from './SectionAnimatedPptPhotoMobile.vue';
import SectionAnimatedPresentation from './SectionAnimatedPresentation.vue';
import SectionClinic from './SectionClinic.vue';
import SectionTreatments from './SectionTreatments.vue';
import SectionAppointment from './SectionAppointment.vue';
import SectionPathologies from './SectionPathologies.vue';
import SectionTeam from './SectionTeam.vue';
import SectionContact from './SectionContact.vue';
import SectionBlog from './SectionBlog.vue';
import FooterBar from '../BasicComponents/FooterBar.vue'

export default {
  name: 'HomePage',
  components: {
    SectionAnimatedPresentation,
    SectionAnimatedPptPhotoMobile,
    SectionClinic,
    SectionTreatments,
    SectionAppointment,
    SectionPathologies,
    SectionTeam,
    SectionContact,
    SectionBlog,
    FooterBar
  },
  data() {
    return {
      isSmallScreen: window.innerWidth < 992,
    };
  },
  mounted() {
    window.addEventListener('resize', this.updateScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateScreenSize);
  },
  methods: {
    updateScreenSize() {
      this.isSmallScreen = window.innerWidth < 992;
    },
  },
}

</script>

<style scoped>
.second-content {
  margin-top: 100svh;
}
</style>
