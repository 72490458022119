<template>
    <section id="mobile-ppt">
        <div id="carouselMobile" 
            class="carousel slide carousel-fade" 
            data-bs-ride="carousel" 
            data-bs-interval="4000"
            data-bs-wrap="true"
            data-bs-touch="true">
            <div class="carousel-inner">
                <div 
                v-for="(card, index) in cards" :key="card.id" 
                :class="['carousel-item', 
                { active: index === 0 }]">
                    <ImageTextCardPpt 
                        class="d-block w-100"
                        :id="card.id"
                        :card="card.card"
                        :name="card.name"
                        :profession="card.profession"
                        :description="card.description"
                        :srcJpg="card.srcJpg"
                        :srcPng="card.srcPng"
                        :srcWebp="card.srcWebp"
                        :altImg="card.altImg"
                        :buttonText="card.buttonText"
                        :routerLink="card.routerLink"
                        :href="card.href"
                    />
                </div>
            </div>
            <button class="carousel-control-prev d-none d-md-block" type="button" data-bs-target="#carouselMobile" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next d-none d-md-block" type="button" data-bs-target="#carouselMobile" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    </section>
</template>

<script>
import ImageTextCardPpt from '@/components/ImageTextCardPpt.vue';
import HumbertoWebp from '../../assets/img/team/webp/humberto-web-hq-900.webp';
import FloresWebp from '../../assets/img/team/webp/hector-web-hq-900.webp';
import TapiaWebp from '../../assets/img/team/webp/fernando-web-hq-900.webp';
import CinthiaWebp from '../../assets/img/team/webp/cinthia-web-hq-900.webp';
import HumbertoPng from '../../assets/img/team/png/humberto-web-hq-900.png';
import FloresPng from '../../assets/img/team/png/hector-web-hq-900.png';
import TapiaPng from '../../assets/img/team/png/fernando-web-hq-900.png';
import CinthiaPng from '../../assets/img/team/png/cinthia-web-hq-900.png';
import LogoWebp from '../../assets/img/logos/web/isotipo-color.webp';
import LogoPng from '../../assets/img/logos/png/isotipo-color.png';

export default {
    name: "SectionAnimatedPptPhotoMobile",
    components: {
        ImageTextCardPpt,
    },
    data() {
        return {
            CinthiaPng, HumbertoPng, TapiaPng, FloresPng,
            CinthiaWebp, HumbertoWebp, TapiaWebp, FloresWebp,
            cards: [
                {
                    id: 'ppt',
                    name: 'Clínica Novocel',
                    profession : 'Traumatología y medicina regenerativa',
                    description : 'La primera clínica traumatológica de Viña del Mar dedicada exclusivamente a tratamientos con células madre para la artrosis',
                    srcWebp : LogoWebp,
                    srcPng : LogoPng,
                    altImg : 'Logo Novocel - Medicina regenerativa',
                    href : '/',
                },
                {
                    id: 'verdugo',
                    name: 'Dr. Humberto Verdugo Marchese',
                    profession: 'Director Médico',
                    description: `Médico cirujano de la Universidad de Valparaíso, especialista en Traumatología y Ortopedia.`,
                    srcWebp: HumbertoWebp,
                    srcPng: HumbertoPng,
                    altImg: 'Dr. Humberto Verdugo Marchese - Director Médico',
                    buttonText: 'Agenda tu hora aquí',
                    href: 'https://s3.philaxmed.cl/ReservaOnline.html?mc=Cl%C3%ADnicaNovocel#_',
                },
                {
                    id: 'flores',
                    name: 'Dr. Héctor Flores',
                    profession: 'Médico traumatólogo',
                    description: 'Médico cirujano especialista en traumatología, ortopedia, y oncología ortopédica.',
                    srcWebp: FloresWebp,
                    srcPng: FloresPng,
                    altImg : 'Dr. Héctor Flores - Médico traumatólogo',
                    buttonText: 'Agenda tu hora aquí',
                    href: 'https://s3.philaxmed.cl/ReservaOnline.html?mc=Cl%C3%ADnicaNovocel#_',
                },
                {
                    id: 'tapia',
                    name: 'Dr. Fernando Tapia',
                    profession: 'Médico especialista en dolor crónico',
                    description: `Médico cirujano de la Universidad de Valparaíso y cirujano plástico.`,
                    srcWebp: TapiaWebp,
                    srcPng: TapiaPng,
                    altImg : 'Dr. Fernando Tapia - Médico especialista en dolor crónico',
                    buttonText: 'Agenda tu hora aquí',
                    href: 'https://s3.philaxmed.cl/ReservaOnline.html?mc=Cl%C3%ADnicaNovocel#_',
                },
                {
                    id: 'bricenio',
                    name: 'Ing. Cinthia Briceño Rosas',
                    profession: 'Directora Científica',
                    description: `Ingeniera en biotecnología de la Universidad Andrés Bello. Especialista en medicina regenerativa.`,
                    srcWebp: CinthiaWebp,
                    srcPng: CinthiaPng,
                    altImg : 'Ing. Cinthia Briceño Rosas - Directora Científica',
                    buttonText: 'Ver Unidad Científica',
                    routerLink: '/blog/post/celulas_madre',
                },
            ]
        }
    },
};
</script>


<style scoped>
#mobile-ppt {
    margin-left: -30px;
    /* height: 100svh; */
    /* max-height: 100svh; */
    /* min-height: 750px; */
    min-width: 100svw;
    position: relative;
    top: -84px;
    margin-bottom: -84px;
    overflow: hidden;
    border-radius: 0 0 16px 16px;
}
#mobile-ppt .carousel-item {
    transition: transform 1s ease-in-out;
}
@media (min-width: 768px) {
    #mobile-ppt {
        margin-left: -45px;
        top: -94px;
    }
}
@media (max-width: 768px) {
    .carousel-control-prev,
    .carousel-control-next {
        display: none;
    }
}
</style>
