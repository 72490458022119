<template>
    <!-- PRESENTACIÓN -->
    <div id="presentation" class="no-decoration" v-if="id == 'ppt'">
        <div id="info-presentation" class="cont">
            <div class="d-flex flex-column">
                <div id="first-logo">
                    <a :href="href">
                        <picture>
                            <source :srcset=srcWebp type="image/webp">
                            <source :srcset=srcPng type="image/png">
                            <img :src=srcWebp :alt=altImg />
                        </picture>
                    </a>
                </div>
                <div id="presentation-text">
                    <h1 class="mb-0 mt-0 pt-0"> Somos </h1>
                    <h1 class="mt-0 mb-1 extrabold">{{ name }}</h1>
                    <h1 class="mt-0 pt-2 light">
                        {{ description }}
                    </h1>
                    <div class="optional-cont">
                        <p class="optional">{{ profession }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- EQUIPO MÉDICO -->
    <div class="no-decoration" v-else>
        <div class="container back-image">
            <picture>
                <source :srcset=srcWebp type="image/webp">
                <source :srcset=srcJpg type="image/jpeg" />
                <source :srcset=srcPng type="image/png">
                <img class="col-12 p-0" :src=srcWebp :alt=altImg />
            </picture>
        </div>
        <div class="container back-style"></div>
        <div class="container text">
            <div>
                <p class="profession  animate__animated animate__fadeInUp">{{ profession }}</p>
                <h3 class="name  animate__animated animate__fadeInUp">{{ name }}</h3>
            </div>
            <div>
                <p class="description  animate__animated animate__fadeInUp">{{ description }}</p>
            </div>
            <a v-if="href && href !== ''" :href="href" class="team-slider__button animate__animated animate__fadeInUp" target="_blank">
                {{ buttonText }}
            </a>
            <router-link v-else-if="routerLink && routerLink !== ''" :to="routerLink" class="team-slider__button animate__animated animate__fadeInUp" :aria-label="buttonText">
                {{ buttonText }}
            </router-link>
        </div>
    </div>
</template>

<script>
import 'animate.css';
export default {
    name: "ImageTextCardPpt",
    props: {
        id: {
            type: String,
        },
        srcWebp: {
            type: String,
        },
        srcPng: {
            type: String,
        },
        srcJpg: {
            type: String,
        },
        altImg: {
            type: String,
        },
        name: {
            type: String,
        },
        profession: {
            type: String,
        },
        description: {
            type: String,
        },
        href: {
            type: String,
        },
        routerLink: {
            type: String,
        },
        buttonText: {
            type: String,
        }
    },
};
</script>

<style lang="scss" scoped>
.no-decoration {
    text-decoration: none;
    height: 100svh;
    min-height: 750px;
    min-width: 100svw;
    background-image: url('../assets/img/clinica/consulta-blur.webp');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat; 
}

.container {
    height: 100svh;
    min-height: 750px;
    min-width: 100svw;
    overflow: hidden;
    padding: 1.5rem;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    border-radius:0 0 16px 16px;
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.name,
.profession,
.description {
    color: var(--dark-violet);
}

.name {
    line-height: 40px;
    font-weight: 800;
    margin-bottom: 5px;
    font-size: 2.2rem;
}

.profession {
    font-size: 1rem;
    margin-bottom: 5px;
    display: block;
    font-weight: 700;
    text-transform: uppercase;
}

.description {
    line-height: 1.8rem;
    font-size: 1.1rem;
    margin: 0 0 16px 0;
    font-weight: 500;
}

.team-slider__button {
    display: inline-flex;
    text-decoration: none;
    justify-content: center;
    text-align: center;
    width: max-content;
    padding: 8px 20px;
    border-radius: 30px;
    color: #ffffff;
    background-image: linear-gradient(120deg, #3c3e6b 42%, #3c3e6b 100%);
    font-size: 1rem;
}

.team-slider__button:hover {
    color: #ffffff;
    background-image: linear-gradient(120deDg, rgb(77, 96, 152) 42%, rgb(77, 96, 152) 100%);
}

.container.back-style{
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(247, 247, 247, 0.7), rgba(247, 247, 247, 1));
    padding: 0;
}

.container.back-image{
    padding: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    object-fit: cover;
}
.container.back-image img{
    height: 100vh;   
    min-height: 750px;
    width: auto;
    min-width: 100vw;
    margin-top: 50px;
}
.container.text{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 30px;
}

/* PRESENTATION */
#presentation {
    #first-logo {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        position: relative;
        top: 25px;
        right: 5px;
        margin-top: -25px;
        img {
            height: 80px;
            max-height: 100px;
            margin-bottom: 15px;
        }
    }
    div.cont {
        padding: 50px 7vw 0 7vw;
        display: flex;
        align-items: center;
        height: 100%;
        div.d-flex {
            div#presentation-text {
                h1 {
                    color: var(--dark-violet);
                    padding-top: 30px;
                    margin-bottom: 0;
                    line-height: 32.2px;
                    font-weight: 300;
                    margin-top: 30px;
                    text-align: left;
                    font-size: 42px;                    
                }
                h1.extrabold{
                    font-weight: 800;
                    font-size: 58px;
                    line-height: 62px;
                    padding-top: 20px;
                }
                h1.light{
                    font-weight: 200;
                    font-size: 27.2px;
                    line-height: 38.5px;
                    margin-bottom: 25px;
                }
                div.optional-cont {
                    border-top: 1px solid var(--dark-violet);
                    border-bottom: 1px solid var(--dark-violet);
                    width: max-content;
                    p.optional {
                        color: var(--dark-violet);
                        text-transform: uppercase;
                        font-size: 14px;
                        margin: 15px 0;
                    }
                }
            }
        }
    }
}

/* MEDIA */
@media screen and (min-width: 580px) {
    #presentation {
        #first-logo {
            width: fit-content;
            display: flex;
            justify-content: flex-start;
            img {
                margin-bottom: 50px;
                height: 100px;
            }
        }
    }
}
@media (min-width: 768px){
    .container.text{
        padding: 30px;
    }
}
@media (min-width: 768px){
    .container.text{
        padding: 45px;
    }
}
</style>