<template>
    <section id="treatments">
        <SectionName text="Tratamientos" class="d-flex justify-content-center" />
        <TitleSecodary class="text-center mt-4" ppalText="Medicina regenerativa y traumatología"/>
        <DecorationCurveDouble class="decoration-curve" v-if="decorationCurve" />
        <div :class="{'cards-treatments': decorationCurve}">
            <div class="cards-treatments-container" :class="{'no-decoration': !decorationCurve}">
                <CardTreatments class="card-item" 
                :background=background
                :icon="Cell" 
                altIcon="Ícono de célula" 
                title="Células madre mesenquimales"
                to="/tratamientos">
                    <template v-slot:description>
                        <ul class="card-description">
                            <li>Capacidad regenerativa y reparadora</li>
                            <li>Propiedades antiinflamatorias y inmunomoduladoras</li>
                            <li>Potencial para el desarrollo de terapias avanzadas</li>
                        </ul>
                    </template>
                </CardTreatments>
                <CardTreatments class="card-item" 
                :background=background
                :icon="Blood" 
                altIcon="Ícono de plasma" 
                title="Plasma rico en plaquetas"
                to="/tratamientos">
                    <template v-slot:description>
                        <ul class="card-description">
                            <li>Estimulación de la curación y regeneración del tejido</li>
                            <li>Reducción de la inflamación y el dolor</li>
                            <li>Mejora de la función articular y la calidad del tejido</li>
                        </ul>
                    </template>
                </CardTreatments>
            </div>
            <div class="cards-treatments-container">
                <CardTreatments class="card-item" 
                :background=background
                :icon="Syringe" 
                altIcon="Ícono de jeringa" 
                title="Ácido Hialurónico"
                to="/tratamientos">
                    <template v-slot:description>
                        <ul class="card-description">
                            <li>Disminuye el dolor articular</li>
                            <li>Mejora el estado funcional de las articulaciones astrósicas</li>
                            <li>Propiedades lubricantes y amortiguadoras</li>
                        </ul>
                    </template>
                </CardTreatments>
                <CardTreatments class="card-item" 
                :background=background
                :icon="Health" 
                altIcon="Ícono de maletín" 
                title="Terapias combinadas"
                to="/tratamientos">
                    <template v-slot:description>
                        <ul class="card-description">
                            <li>Potenciación de la eficacia terapéutica</li>
                            <li>Sinergia entre tratamientos</li>
                            <li>Enfoque integral y personalizado</li>
                        </ul>
                    </template>
                </CardTreatments>
            </div>
        </div>
    </section>
</template>

<script>
import SectionName from '@/components/SectionName.vue';
import TitleSecodary from '@/components/TitleSecodary.vue';
import DecorationCurveDouble from '@/components/DecorationCurveDouble.vue';
import CardTreatments from '@/components/CardTreatments.vue';
import Cell from '@/assets/img/icons/webp/celula.webp';
import Health from '@/assets/img/icons/webp/salud.webp';
import Syringe from '@/assets/img/icons/webp/jeringa.webp';
import Blood from '@/assets/img/icons/webp/sangre.webp';


export default {
    name: 'SectionTreatments',
    components: {
        SectionName,
        TitleSecodary,
        DecorationCurveDouble,
        CardTreatments,
    },
    props: {
        decorationCurve: {
            type: Boolean,
            default: true,
        },
        background: {
            type: Boolean,
        }
    },
    data() {
        return {
            Cell : Cell,
            Health : Health,
            Syringe : Syringe,
            Blood : Blood,
        }
    },
}
</script>

<style scoped>
#treatments{
    margin-top: 4rem;
}
.decoration-curve{
    position: relative;
    top: -310px;
    display: flex;
    justify-content: center;
}
.cards-treatments{
    max-width: 1400px;
    margin: auto;
    margin-top: -220px;
}
ul.card-description{
    list-style-type: "- ";
    font-weight: 500;
    font-size: 0.95rem;
    line-height: 1.5rem;
    padding-left: 0.9rem;
    margin-bottom: 0;
}
.cards-treatments-container{
    margin-bottom: 1rem;
    min-width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;    
}
.cards-treatments-container:first-of-type{
    margin-top: 3rem;
}
.card-item{
    flex: 1 0 100% ;
}
.no-decoration{
    margin-top: 20px;
}
/* RESPONSIVE */

@media (min-width:650px) {
    .cards-treatments{
        margin-top: -300px;
    }
}

@media (min-width:992px) {
    .card-item{
        flex: 0 0 calc(50% - 10px);
    }
    .cards-treatments{
        margin-top: -350px;
    }
    .cards-treatments-container:first-of-type{
        margin-top: 1rem;
    }
    /* .no-decoration{
        padding-top: 2.5rem;
        border: red solid 2px;
    } */
}

@media (min-width:1200px) {
    .card-item{
        flex: 0 0 calc(33.33% - 13px);
    }
    .cards-treatments-container:last-of-type{
        justify-content: flex-end;
    }
    .cards-treatments{
        margin-top: -400px;
    }
}
@media (min-width:1300px) {
    .cards-treatments{
        margin-top: -500px;
    }
}
</style>